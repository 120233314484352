import type { FunctionComponent, MouseEventHandler } from "react";
import { useCallback, useState } from "react";
import { CSVLink } from "react-csv";
import { DateRangePicker } from "react-date-range";
import { Popover } from "@mui/material";
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import TooltipButton from "../../components/TooltipButton";
import { useHttpRequest, useSelector, useSnackbar } from "../../hooks";
import { addDays, endOfDay, startOfDay, subDays } from "date-fns";

type SelectionRange = {
  startDate: Date;
  endDate: Date;
};

const minDate = startOfDay(new Date("2024-01-01T00:00:00"));
const selectionRangeKey = "selection";

const reportHeaders = [
  { label: "Date", key: "date" },
  { label: "Time", key: "time" },
  { label: "Description", key: "event_type" },
  { label: "Guest", key: "guest_invitation_id" },
  { label: "Details", key: "data" },
];

const dateOnly = (date: Date) => {
  var dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0];
  return dateString;
};

const SessionReportRange: FunctionComponent = () => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { productionGuid } = useSelector((state) => state.queue.showInfo);

  const accountName = useSelector((state) => state.queue.network);
  const productionName = useSelector((state) => state.queue.currentShow);

  const today = startOfDay(new Date());

  const defaultSelectionRange: SelectionRange = {
    startDate: subDays(today, 60),
    endDate: endOfDay(today),
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [okToGenerate, setOkToGenerate] = useState<boolean>(true);
  const [selectionRange, setSelectionRange] = useState<SelectionRange>(
    defaultSelectionRange
  );

  const errorSnackbar = (errorMessage: string = "Unknown Error") => {
    enqueueSnackbar(errorMessage, {
      hideIconVariant: true,
      variant: "error",
    });
  };

  const [requestEventLogsForRange, requestEventLogsForRangeState] =
    useHttpRequest(`admin_proxy/productions/${productionGuid}/sessions`, {
      method: "get",
      searchParams: {
        to: dateOnly(addDays(selectionRange.endDate, 1)), // Add 1 day for completeness
        from: dateOnly(selectionRange.startDate),
      },
      onError: async (e: any) => {
        errorSnackbar(e?.message);
      },
    });

  const fetchEventLogs = useCallback(async () => {
    try {
      const eventLogs = await requestEventLogsForRange();

      if (eventLogs) {
        /*console.log("got event logs");
        console.dir(eventLogs);*/

        if (eventLogs.length === 0) {
          errorSnackbar(
            "Report Generation: No results were found for the selected date range.  Please try a different selection."
          );
        } else {
          const snackbarKey = enqueueSnackbar(
            <span>
              Report Generation: {eventLogs.length} entries found.&nbsp;&nbsp;
              <CSVLink
                data={eventLogs}
                filename={`${accountName}_${productionName}_to_${dateOnly(
                  selectionRange.endDate
                )}_from_${dateOnly(selectionRange.startDate)}.csv`}
                headers={reportHeaders}
                id="csv-download-link"
                onClick={() => {
                  try {
                    closeSnackbar(snackbarKey);
                  } catch (e: any) {
                    //console.error("Unable to close snackbar with error", e);
                    /* Do nothing */
                  }
                }}
              >
                <b>Click to Download</b>
              </CSVLink>
            </span>,
            {
              hideIconVariant: true,
              persist: true,
              variant: "success",
            }
          );
        }
      }
    } catch (e: any) {
      errorSnackbar(e?.message);
    }
  }, [requestEventLogsForRange]);

  const handleOpen: MouseEventHandler<HTMLButtonElement> = (event) => {
    setOkToGenerate(true);
    setSelectionRange(defaultSelectionRange);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOkToGenerate(true);
    setAnchorEl(null);
  };

  const handleRangeSelection = (ranges) => {
    const { startDate, endDate } = ranges[selectionRangeKey];
    const range = {
      ...ranges[selectionRangeKey],
      startDate: startOfDay(startDate),
      endDate: endOfDay(endDate),
    };

    setOkToGenerate(true);
    setSelectionRange(range);
  };

  const handleSubmit = async () => {
    setOkToGenerate(false);
    await fetchEventLogs();
  };

  const open = Boolean(anchorEl);
  const id = open ? "date-range-selector" : undefined;

  return (
    <>
      <TooltipButton
        aria-describedby={id}
        color="info"
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        isDisabled={requestEventLogsForRangeState.loading}
        text={"Generate Report"}
        title="Generate a report based on date range"
        variant="contained"
        onClick={handleOpen}
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        id={id}
        open={open}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleClose}
      >
        <DateRangePicker
          calendarFocus="forwards"
          direction="horizontal"
          footerContent={
            <div
              style={{
                height: "35%",
                width: "100%",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              {okToGenerate && (
                <TooltipButton
                  color="info"
                  text="Generate Report"
                  title={"Generate a report for the selected date range"}
                  variant="contained"
                  onClick={handleSubmit}
                />
              )}
              {!okToGenerate && (
                <span>
                  <i>
                    {requestEventLogsForRangeState.error
                      ? ""
                      : "Select dates for report"}
                  </i>
                </span>
              )}
            </div>
          }
          maxDate={today}
          minDate={minDate}
          months={3}
          preventSnapRefocus={true}
          ranges={[{ ...selectionRange, key: selectionRangeKey }]}
          showDateDisplay
          onChange={handleRangeSelection}
        />
      </Popover>
    </>
  );
};

export default SessionReportRange;
